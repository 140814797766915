import getConfig from "next/config";
import { contentReader, snowplowSchemaUrl } from "../../utils/helperFunctions";
import Prelem from "./Prelem";

const { publicRuntimeConfig = {} } = getConfig() || {};
interface IChildren {
  PrelemTag: Array<string>;
  PrelemId: string;
  PrelemName: string;
  SeoEnabled: boolean;
  AnalyticsEnabled: boolean;
  DocumentPath: string;
  DocumentType: string;
  IsHidden: boolean;
  StructuredData: Object;
}

const PrelemComponent = (props: any = {}) => {
  const { myRefs = [], pageData = {}, instances = {}, prelemBaseEndpoint = {} } = props;
  const { Children = [] } = pageData;

  const secondaryArgs = {
    ...snowplowSchemaUrl(),
    prelemBaseEndpoint,
    gcpUrl: publicRuntimeConfig.NEXT_GCP_URL,
    bucketName: publicRuntimeConfig.NEXT_BUCKET_NAME,
    sitename: prelemBaseEndpoint?.site_host,
    isEditorJSEnable: publicRuntimeConfig.NEXT_IS_EDITORJS_ENABLE?.toLowerCase?.() === "true",
    mainSellerHost: publicRuntimeConfig?.NEXT_MAIN_SELLER_HOST,
  };

  const getPrelems = () => {
    return Children?.map((item: IChildren, key: number) => {
      try {
        const prelemContentProp = contentReader(item?.DocumentPath, pageData?.Content);
        const analyticsEnabled = Boolean(
          pageData?.AnalyticsEnable &&
            item?.AnalyticsEnabled &&
            Object.keys(instances || {})?.length,
        );
        const prelemAnalyticsProp = {
          pageId: pageData?.Page,
          pageTitle: pageData?.Title,
          pageDesc: pageData?.PageSettings?.PageDescription || "",
          pageTags: pageData?.PageSettings?.PageTags?.join(", "),
          prelemId: item?.PrelemId,
          prelemTitle: item?.PrelemName,
          prelemTags: item?.PrelemTag?.join(", "),
          prelemPosition: key + 1,
          isAuthoring: false,
          isSeoEnabled: item?.SeoEnabled,
          isAnalyticsEnabled: analyticsEnabled,
        };
        const prelemAuthoringHelper = {
          //isAuthoring: true,
          //isSeoEnabled: true,
          //isAnalyticsEnabled: true,
          isEditPage: false,
          innerRef: myRefs.current[key],
          isModalShow: true,
        };

        // based on Analytics provier instance length we are setting prelem's analyticsEnabled true OR false
        return (
          <div id={`Prelem${key + 1}`} key={`Prelem-key${key + 1}`}>
            {prelemContentProp ? (
              <Prelem
                content={prelemContentProp}
                analytics={prelemAnalyticsProp}
                authoringHelper={prelemAuthoringHelper}
                secondaryArgs={secondaryArgs}
                prelemId={item?.PrelemId}
              />
            ) : null}
          </div>
        );
      } catch (e) {
        return null;
      }
    });
  };

  return <div>{Children?.length > 0 ? getPrelems() : null}</div>;
};

export default PrelemComponent;
